.container {
    color: var(--colorText);
    font-family: var(--fontFamily);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 10%;
    padding: 30px;
}

.container h1 {
    color: white;
    font-size: 48px;
}

.container a {
    margin: 0 1rem;
    padding: 0.75rem 1.5rem;
    color: #fff;
    font-family: var(--fontFamily);
    font-size: 18px;
    line-height: 25px;
    border: none;
    cursor: pointer;
    outline: none;

    background: var(--colorButton);
    border-radius: 5px;
    font-weight: 500;
}