*:not(dialog) {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    scroll-margin: 10vh;
}

.banner {
    background-color: #0075ff;
    padding: 16px 20px;
    display: flex;
    justify-content: center;
    font-family: var(--fontFamily);
    color: white;
}

body {
    background-color: var(--colorBG);
    color: var(--colorText);
}

h1, h2, .title-font {
    font-family: var(--emphasisedFontFamily);
}

.emphasised-text {
    font-family: var(--emphasisedFontFamily);
}

a {
    @apply text-primary-500 font-semibold;
    text-decoration: none;
}

.button {
    padding: 0.75rem 1.5rem;
    color: #fff;
    font-family: var(--fontFamily);
    font-size: 1.5rem;
    line-height: 1.5;
    border: none;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
    @apply bg-primary-500;
}

.button.small {
    font-size: 1.1rem;
}

.gradientText {
    background: var(--gradientText);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.scaleOnHover {
    transition: transform .2s;
}

.scaleOnHover:hover {
    transform: scale(1.1);
}

.scale-up-center {
	-webkit-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}
.scale-up-center:hover {
    transform: scale(1.1);
}

.default-scroll-transition {
    @apply motion-safe:translate-y-6 motion-safe:opacity-0;
}