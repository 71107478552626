.dialog::backdrop {
    position: fixed;
    inset: 0px;
    background: hsl(0 0% 0% / 50%);
    backdrop-filter: blur(2px);
  }

.dialog {
    background-color: var(--colorPopUp);
    min-width: 300px;
    border: none;
    padding: 0;
}

.popup-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem;
}

.popup-content {
    padding: 2rem;
}

.popup-close {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

/* Pop Up Content */

.popup-title h1 {
    font-family: var(--fontFamily);
    font-size: 36px;
    line-height: 44px;
    color: var(--colorText);

    margin: 1rem 0 0.75rem 0.75rem;
}

.popup-input input {
    padding: 0.75rem;
    margin: 1.5rem 0;

    background-color: var(--colorAbout);
    border: 0px;
    color: var(--colorText);

    min-width: 400px;
}

.popup-input input:autofill,
.popup-input input:-webkit-autofill,
.popup-input input:autofill:focus,
.popup-input input:-webkit-autofill:focus,
.popup-input input:autofill:active,
.popup-input input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 100px var(--colorAbout) inset;
    -webkit-text-fill-color: var(--colorText);
}

.popup-input input::placeholder {
    color: var(--colorText);
}

.popup-input * {
    font-family: var(--fontFamily);
    font-size: 18px;
    font-weight: 600;
}

.popup-input *:focus {
    outline: none;
}

.popup-submit button {
    margin: 1.5rem 1.5rem 0;
    padding: 0.75rem;
    border: none;
    cursor: pointer;
    outline: none;

    background: var(--colorButton);
    border-radius: 5px;

    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

@media screen and (max-width:550px) {
    .popup-input input {
        min-width: auto;
    }
}

@media screen and (max-width:400px) {
    .popup-container {      
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .popup-close {
        margin: 0.75rem 1.25rem 0 0;
    }
}