.hero {
    /* height: 75vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.basic-link {
    position: relative;
}

.basic-link:before {
    content: "";
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: scaleX(0);
    transform-origin: bottom right;
    background: var(--primary);
    transition: transform 0.25s ease-out;
    
}

.basic-link:hover:before {
    transform: scaleX(1);
    transform-origin: bottom left;
    transition: transform 0.25s cubic-bezier(0.175, 0.485, 0.32, 1.275);
}