.header {
    transition: all 0.75s var(--slow-bezier);
}

.navbar {
    background: linear-gradient(0deg, #009fff10, var(--header));
}

.nav-link {
    transition: all 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
    @apply relative origin-bottom hover:scale-110 max-md:w-max;
}

.nav-link::after {
    transform: none;
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -0.35rem;
    left: 0;
    width: 0;
    background-color: var(--text);
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.7, 1);
}

.nav-link:hover::after {
    width: 100%;
}

.logo {
    font-size: 2.5rem;
    padding: 0.4rem 0.5rem;
    line-height: 1;
    border-radius: 5px;
    @apply bg-primary-500;
}

.default-font {
    padding: 0.35rem 0.45rem 0.2rem;
    transition: all 0.75s var(--slow-bezier);
}

.default-font-scrolled {
    font-size: 1.75rem;
    padding: 0.3rem 0.3rem 0.1rem;
}

.mogra {
    padding: 0.4rem 0.45rem 0;
}

.mogra-scrolled {
    padding: 0.4rem 0.4rem 0.1rem;
}

.black-ops-one {
    padding: 0.35rem 0.45rem 0.3rem 0.55rem;
}

.black-ops-one-scrolled {
    padding: 0.3rem 0.35rem 0.2rem 0.4rem;
}

.zen-dots {
    padding: 0.35rem 0.45rem 0.25rem;
}

.zen-dots-scrolled {
    padding: 0.3rem 0.3rem 0.15rem;
}

.slackey {
    padding: 0.3rem 0.4rem 0.3rem 0.5rem;
}

.slackey-scrolled {
    padding: 0.4rem 0.4rem 0.3rem 0.5rem;
}

.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1.875rem;
    height: 1.5rem;
    cursor: pointer;
}

.hamburger span {
    height: 3px;
    width: 100%;
    background: var(--text);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: all 0.5s var(--slow-bezier);
}

.hamburger span:nth-of-type(1) {
    transform-origin: right center;
}

.hamburger span:nth-of-type(2) {
    transform-origin: right center;
}

.hamburger span:nth-of-type(3) {
    transform-origin: right center;
}

.selected span:nth-of-type(1) {
    transform: rotate(-45deg);
}

.selected span:nth-of-type(2) {
    width: 0%;
    opacity: 0;
}

.selected span:nth-of-type(3) {
    transform: rotate(45deg);
}

.mobile-menu {
    transition: all 0.75s var(--slow-bezier);
    background: linear-gradient(#009fff20, var(--header));
    box-shadow: #009fff10 -5px 0 30px 10px, #009fff10 5px 0 30px 10px;
}