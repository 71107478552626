.project-code:hover {
    filter: drop-shadow(0 0 20px #4078C050);
}

.project {
    background: radial-gradient(circle at 100% 90%, rgba(119, 146, 252, 0.1) 0%, rgba(57, 133, 255, 0) 80%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 50px 30px, rgba(0, 0, 0, 0.06) 0px 12px 24px 4px;
}

.project-icon {
    transform-origin: top left;
}

.project:hover .project-icon {
    @apply scale-110;
}

.window:hover .window-icon {
    opacity: 1;
}

.window-icon {
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
}