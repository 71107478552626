/* This creates global variables for use in other components use the React root component */ 

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Fugaz+One&family=Mogra&family=Rubik+Spray+Paint&family=Rubik:ital,wght@0,300..900;1,300..900&family=Slackey&family=Zen+Dots&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .max-section-size {
    @apply max-w-5xl 3xl:max-w-6xl;
  }

  .section-sapcing {
    @apply mb-36 px-4 xs:px-8 sm:px-12 md:px-16;
  }

  .title {
    @apply mx-auto w-full max-section-size;
  }

  .title h1,
  .title h2,
  .title h3,
  .title h4,
  .title h5,
  .title h6 {
    @apply relative w-fit text-3xl md:text-5xl mb-3 uppercase;
  }

  .title h1::after,
  .title h2::after,
  .title h3::after,
  .title h4::after,
  .title h5::after,
  .title h6::after {
    position: absolute;
    content: "";
    bottom: -2px;
    left: -1%;
    height: 5px;
    width: 102%;
    background-color: var(--primary);
    transform: rotate(-0.8deg);
    border-radius: 2px;
  }

  .title .double-word {
    @apply max-sm:block max-sm:mb-4;
  }

  .title .double-word h1,
  .title .double-word h2,
  .title .double-word h3,
  .title .double-word h4,
  .title .double-word h5,
  .title .double-word h6 {
    @apply max-sm:inline;
  }

  .title p {
    @apply max-md:xs:px-4 mb-8 text-slate-400;
  }

  .card {
    @apply rounded-2xl bg-primary-950 p-8;
  }
}

:root {
  --emphasisedFontFamily: Fugaz One, Poppins, sans-serif; /* Changed by App.tsx */
  --fontFamily: Poppins, sans-serif;

  --gradientText: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradientBar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
/* Colors */

  --primary: #009fff;
  --secondary: #696969;
  --accent: #2D6BFB;
  --text: #E3E3E3;
  --header: #010E25;

/* Elements */
  --colorBG: #020617;
  --colorFooter: #031B34;
  --colorAbout: #042c54;
  --colorText: var(--text);
  --colorSubtext: #FF8A71;
  --colorButton: var(--primary);
  --colorPopUp: #043a70;

  /* Animations */
  --slow-bezier: cubic-bezier(0.075, 0.82, 0.165, 1);
}